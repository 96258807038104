/*
 *
 * language-provider constants
 *
 */

export const CHANGE_LOCALE = "app/LanguageToggle/CHANGE_LOCALE";

export const BACKEND_ERROR_MESSAGES = [
  "EMAIL_ALREADY_EXISTS",
  "USER_NOT_FOUND",
  "INVALID_QUESTIONNAIRE_EMAIL"
];
