import React from "react";
import { Link } from "react-router-dom";
import languageKeys from "../translations";
import {
  FileAddOutlined,
  HistoryOutlined,
  LinkOutlined,
  MobileOutlined,
  FileImageOutlined,
  NotificationOutlined,
  KeyOutlined
} from "@ant-design/icons";

const links = (state, translate) => ({
  backofficeValues: (
    <Link to="/admin/backoffice-values">
      <KeyOutlined />
      <span>{translate(languageKeys.editableValues)}</span>
    </Link>
  ),
  character: (
    <Link to="/admin/characters">
      <span>{translate(languageKeys.characterMenu)}</span>
    </Link>
  ),
  season: (
    <Link to="/admin/seasons">
      <span>{translate(languageKeys.seasonMenu)}</span>
    </Link>
  ),
  naturalElement: (
    <Link to="/admin/natural-elements">
      <span>{translate(languageKeys.naturalElementMenu)}</span>
    </Link>
  ),
  criteria: (
    <Link to="/admin/criteria">
      <span>{translate(languageKeys.criteriaMenu)}</span>
    </Link>
  ),
  criteriaUsage: (
    <Link to="/admin/criteria-usage">
      <span>{translate(languageKeys.criteriaUsageMenu)}</span>
    </Link>
  ),
  metaSkill: (
    <Link to="/admin/meta-skill">
      <span>{translate(languageKeys.metaSkillMenu)}</span>
    </Link>
  ),
  principle: (
    <Link to="/admin/principle">
      <span>{translate(languageKeys.principleMenu)}</span>
    </Link>
  ),
  element: (
    <Link to="/admin/elements">
      <span>{translate(languageKeys.elementMenu)}</span>
    </Link>
  ),
  feeling: (
    <Link to="/admin/feelings">
      <span>{translate(languageKeys.feelingsMenu)}</span>
    </Link>
  ),
  organ: (
    <Link to="/admin/organs">
      <span>{translate(languageKeys.organsMenu)}</span>
    </Link>
  ),
  algorithmOutput: (
    <Link to="/admin/algorithm-output">
      <span>{translate(languageKeys.algorithmOutputMenu)}</span>
    </Link>
  ),
  generalOutput: (
    <Link to="/admin/general-output">
      <span>{translate(languageKeys.generalOutputMenu)}</span>
    </Link>
  ),
  input: (
    <Link to="/admin/input">
      <FileImageOutlined />
      <span>{translate(languageKeys.inputMenu)}</span>
    </Link>
  ),
  app: (
    <Link to="/admin/mobile-apps">
      <MobileOutlined />
      <span>{translate(languageKeys.mobileApkMenu)}</span>
    </Link>
  ),
  pushNotifications: (
    <Link to="/admin/push-notifications">
      <NotificationOutlined />
      <span>Push Notifications</span>
    </Link>
  ),
  questionnaires: (
    <Link to="/admin/questionnaires">
      <span>{translate(languageKeys.questionnairesMenu)}</span>
    </Link>
  ),
  usersBackoffice: (
    <Link to="/admin/users/backoffice">
      <span>{translate(languageKeys.userBackofficeMenu)}</span>
    </Link>
  ),
  usersApp: (
    <Link to="/admin/users/app">
      <span>{translate(languageKeys.userAppMenu)}</span>
    </Link>
  ),
  questions: (
    <Link to="/admin/questions">
      <span>{translate(languageKeys.questionsMenu)}</span>
    </Link>
  ),
  answersOptions: (
    <Link to="/admin/answers-options">
      <span>{translate(languageKeys.answer_options)}</span>
    </Link>
  ),
  invites: (
    <Link to="/admin/invites">
      <LinkOutlined /> &nbsp;
      <span>{translate(languageKeys.invitesMenu)}</span>
    </Link>
  ),
  answerQuestionnaire: (
    <Link
      to={{
        pathname: `/user/${state.userId}/answer-questionnaire`,
        state: { userId: state.userId }
      }}
    >
      <FileAddOutlined /> &nbsp;
      <span>{translate(languageKeys.questionnaire)}</span>
    </Link>
  ),
  consultantInvite: (
    <Link
      to={{
        pathname: `/user/${state.userId}/answer-questionnaire`,
        state: { userId: state.userId }
      }}
    >
      <FileAddOutlined /> &nbsp;
      <span>{translate(languageKeys.invite)}</span>
    </Link>
  ),
  history: (
    <Link
      to={{
        pathname: `/user/${state.userId}/answer-questionnaire/history`,
        state: { userId: state.userId }
      }}
    >
      <HistoryOutlined /> &nbsp;
      <span>{translate(languageKeys.history)}</span>
    </Link>
  ),
  criteriaManagement: (
    <Link
      to={{
        pathname: `/admin/criteria-management`,
        state: { userId: state.userId }
      }}
    >
      <span>{translate(languageKeys.criteriaManagementMenu)}</span>
    </Link>
  ),
  algorithmConfig: (
    <Link
      to={{
        pathname: `/admin/algorithm-config`,
        state: { userId: state.userId }
      }}
    >
      <span>{translate("algorithm_config")}</span>
    </Link>
  )
});

export default links;
