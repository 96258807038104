/* eslint-disable no-unused-expressions */
import React from "react";
import {
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from "@ant-design/icons";
import { Layout, Breadcrumb } from "antd";
import { injectIntl } from "react-intl";
import LanguageSelector from "../LanguageSelector";
import languageKeys from "./translations";
import classes from "./style.css";
import logoText from "assets/images/icon.png";
import logo from "assets/images/icon.png";
import Background from "assets/images/menu.png";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import jwt from "jwt-decode";
import { makeSelectLocale } from "../../translations/language-provider/selectors";
import UserMenu from "components/DropdownUserMenu";
import { bindActionCreators } from "redux";
import { updatePassword } from "lib/providers/auth-provider/actions";
import {
  makeSelectToken,
  makeSelectRole,
  makeSelectIsAuth
} from "lib/providers/auth-provider/selectors";
import AdminMenu from "./menus/adminMenu";
import ConsultantMenu from "./menus/consultantMenu";
import links from "./menus/links";

const { Header, Sider, Content } = Layout;

class SiderDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      userId: null,
      user: null
    };
  }

  componentDidMount() {
    if (this.props.token) this.setState({ userId: jwt(this.props.token).sub });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  translate = id => {
    return this.props.intl.formatMessage({ id });
  };

  menuProps = () => ({
    key: "Menu",
    output: this.translate(languageKeys.outputMenu),
    generalOutput: this.translate(languageKeys.generalOutputMenu),
    algorithmOuput: this.translate(languageKeys.algorithmOutputMenu),
    criteriaManagement: this.translate(languageKeys.criteriaManagementMenu),
    answerComponent: this.translate(languageKeys.answerComponentMenu),
    mobileApk: this.translate(languageKeys.mobileApkMenu),
    character: this.translate(languageKeys.characterMenu),
    season: this.translate(languageKeys.seasonMenu),
    naturalElement: this.translate(languageKeys.naturalElementMenu),
    feelings: this.translate(languageKeys.feelingsMenu),
    algorithm: this.translate(languageKeys.algorithmMenu),
    secondaryAlgorithm: this.translate(languageKeys.secondaryAlgorithmMenu),
    criteria: this.translate(languageKeys.criteriaMenu),
    criteriaUsage: this.translate(languageKeys.criteriaUsageMenu),
    metaSkill: this.translate(languageKeys.metaSkillMenu),
    principle: this.translate(languageKeys.principleMenu),
    element: this.translate(languageKeys.elementMenu),
    dashboard: this.translate(languageKeys.dashboard),
    questionnaires: this.translate(languageKeys.questionnairesMenu),
    questions: this.translate(languageKeys.questionsMenu),
    users: this.translate(languageKeys.usersMenu),
    other: this.translate(languageKeys.otherMenu),
    dataBase: this.translate(languageKeys.data_base),
    answerOptions: this.translate(languageKeys.answer_options),
    answerQuestionnaire: this.translate(languageKeys.analysis),
    attributes: this.translate(languageKeys.attributesMenu),
    input: this.translate(languageKeys.inputMenu),
    new: this.translate(languageKeys.questionnaire),
    history: this.translate(languageKeys.history),
    userId: this.state.userId,
    links: links(this.state, this.translate),
    ...this.props.keys
  });

  render() {
    const {
      breadcrumb,
      level,
      questionnaireName,
      role,
      userSelected,
      element
    } = this.props;
    return (
      <Layout key={"admin-page"} style={{ height: "100vh" }}>
        <Layout id="components-layout-demo-custom-trigger">
          <Sider
            style={{
              backgroundImage: `url(${Background})`,
              backgroundSize: "contain",
              height: "100vh",
              overflowY: "auto"
            }}
            width={230}
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
          >
            <div className={"logo-container"}>
              <img
                className={classes.logo}
                alt="sensor logo"
                src={this.state.collapsed ? logo : logoText}
              />
            </div>
            {role === "admin" ? (
              <AdminMenu {...this.menuProps()} />
            ) : (
              <ConsultantMenu {...this.menuProps()} />
            )}
          </Sider>
          <Layout>
            <Header
              style={{
                background: "#fff",
                padding: 0,
                position: "relative",
                height: "9%",
                boxShadow: "0 5px 5px -5px rgba(0,0,0,0.4)"
              }}
            >
              {this.state.collapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  style={{ verticalAlign: "unset", lineHeight: 0 }}
                  onClick={this.toggle}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  style={{ verticalAlign: "unset", lineHeight: 0 }}
                  onClick={this.toggle}
                />
              )}
              <span style={{ position: "absolute", left: 70, top: 17 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <HomeOutlined />
                  </Breadcrumb.Item>
                  {level ? (
                    <Breadcrumb.Item>
                      {this.translate(`${level}`)}
                    </Breadcrumb.Item>
                  ) : null}
                  {breadcrumb
                    ? breadcrumb.map(path => {
                        return (
                          <Breadcrumb.Item key={path}>
                            {links(this.state, this.translate)[path]}
                          </Breadcrumb.Item>
                        );
                      })
                    : null}
                  {questionnaireName ? (
                    <Breadcrumb.Item>
                      {
                        questionnaireName.filter(
                          t => t.language.code === this.props.locale
                        )[0].translation
                      }
                    </Breadcrumb.Item>
                  ) : null}
                  {userSelected ? (
                    <Breadcrumb.Item>{userSelected}</Breadcrumb.Item>
                  ) : null}
                  {element ? (
                    <Breadcrumb.Item>{element}</Breadcrumb.Item>
                  ) : null}
                </Breadcrumb>
              </span>
              <span className={classes.corner}>
                <LanguageSelector />
                <span style={{ paddingLeft: "20px" }}>
                  <UserMenu
                    userId={this.state.userId}
                    token={this.props.token}
                    logout={this.props.logout}
                  />
                </span>
              </span>
            </Header>
            <Content style={{ padding: "1%" }}>{this.props.children}</Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  token: makeSelectToken(),
  role: makeSelectRole(),
  auth: makeSelectIsAuth()
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updatePassword
    },
    dispatch
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(injectIntl(SiderDemo));
