import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { makeSelectRole } from "../lib/providers/auth-provider/";
import connect from "react-redux/es/connect/connect";
import { logout } from "../lib/providers/auth-provider/";
//import { LoadingOutlined } from '@ant-design/icons';
import MasterPage from "shared-components/MasterPageAdmin";

const asyncComponent = (importComponent, accesList) => {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }

    async UNSAFE_componentWillMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;

      if (accesList && accesList.whitelist.indexOf(this.props.role) === -1) {
        return (
          <Redirect
            push={true}
            to={
              accesList[this.props.role] ? accesList[this.props.role] : "/login"
            }
          />
        );
      }

      switch (this.props.role) {
        default:
          return C ? (
            <C logout={this.props.logout} {...this.props} />
          ) : (
            <MasterPage logout={this.props.logout}>
              <div style={{ textAlign: "center" }}></div>
            </MasterPage>
          );
      }
    }
  }

  const mapStateToProps = state => ({
    role: makeSelectRole()(state)
  });
  const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
    //getQuestionnaires: () => dispatch(getQuestionnaires()),
    //getQuestionnaireCategories: () => dispatch(getQuestionnaireCategories('6f0125fb-f400-4375-aa73-34733a4e937e'))
  });

  return connect(mapStateToProps, mapDispatchToProps)(AsyncComponent);
};

export default asyncComponent;
